import Axios, { setAuthHeaders } from '@/services/Axios';
import { ListItem } from '../common/models/ListItem';

const resource = '/user';

export default {
  /**
   * @param {number} userId
   * @returns {Promise<ListItem[]>}
   */
  async getUserStores(userId) {
    const response = await Axios.get(`${resource}/${userId}/stores`);
    return response.data.map(item => (new ListItem(item)));
  },

  async getUserAttributes(userId) {
    const headers = setAuthHeaders({});

    const { data } = await Axios.get(`${resource}/${userId}/attributes`, { headers });
    return data.data;
  },
};
