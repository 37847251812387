import { PurchaseOrderProductItem } from './PurchaseOrderSingleItem';
import CurrencyFormatter from './CurrencyFormatter';

export class SalesReportSingleItem {
  constructor(id, foil, client, saleDt, status, totalAmount, employee, cashPayments,
    debitCardPayments, creditCardPayments, checkPayments, transferPayments,
    realCashPayments, products) {
    this.id = id;
    this.foil = foil;
    this.client = client;
    this.saleDt = saleDt;
    this.status = status;
    this.totalAmount = totalAmount;
    this.employee = employee;
    this.cashPayments = cashPayments;
    this.debitCardPayments = debitCardPayments;
    this.creditCardPayments = creditCardPayments;
    this.checkPayments = checkPayments;
    this.transferPayments = transferPayments;
    this.realCashPayments = realCashPayments;
    this.products = [];
    products.forEach((x) => {
      const item = new PurchaseOrderProductItem(x.costDiscount, x.currentCost, x.product, x.isGift);
      this.products.push(item);
    });
  }

  get statusStr() {
    let statusStr = '';
    if (this.status === 0) {
      statusStr = 'Pendiente';
    } else if (this.status === 1) {
      statusStr = 'Contabilizada';
    }
    return statusStr;
  }

  get clientName() {
    if (this.client) {
      return this.client.name;
    }
    return 'Anónimo';
  }

  get saleDtStr() {
    let saleDt = this.saleDt.split('T')[0];
    saleDt = saleDt.split('-');
    return `${saleDt[2]}/${saleDt[1]}/${saleDt[0]}`;
  }

  get totalAmountStr() {
    return CurrencyFormatter.format(this.totalAmount);
  }

  get employeeName() {
    if (this.employee) {
      return this.employee.user ? `${this.employee.user.firstname} ${this.employee.user.lastName}` : 'Sin vendedor';
    }
    return 'Sin vendedor';
  }

  get cashAmountStr() {
    let totalCashAmount = 0;
    if (this.cashPayments.length > 0) {
      this.cashPayments.forEach((x) => {
        totalCashAmount += x.amount;
      });
    }
    return CurrencyFormatter.format(totalCashAmount);
  }

  get realCashAmountStr() {
    let totalCashAmount = 0;
    if (this.realCashPayments.length > 0) {
      this.realCashPayments.forEach((x) => {
        totalCashAmount += x.amount;
      });
    }
    return CurrencyFormatter.format(totalCashAmount);
  }

  get changeAmountStr() {
    let totalChangeAmount = 0;
    if (this.cashPayments.length > 0) {
      this.cashPayments.forEach((x) => {
        totalChangeAmount += x.change;
      });
    }
    return CurrencyFormatter.format(totalChangeAmount);
  }

  get debitCardAmountStr() {
    let totalAmount = 0;
    if (this.debitCardPayments.length > 0) {
      this.debitCardPayments.forEach((x) => {
        totalAmount += x.amount;
      });
    }
    return CurrencyFormatter.format(totalAmount);
  }

  get creditCardAmountStr() {
    let totalAmount = 0;
    if (this.creditCardPayments.length > 0) {
      this.creditCardPayments.forEach((x) => {
        totalAmount += x.amount;
      });
    }
    return CurrencyFormatter.format(totalAmount);
  }

  get checkAmountStr() {
    let totalAmount = 0;
    if (this.checkPayments.length > 0) {
      this.checkPayments.forEach((x) => {
        totalAmount += x.amount;
      });
    }
    return CurrencyFormatter.format(totalAmount);
  }

  get transferAmountStr() {
    let totalAmount = 0;
    if (this.transferPayments.length > 0) {
      this.transferPayments.forEach((x) => {
        totalAmount += x.amount;
      });
    }
    return CurrencyFormatter.format(totalAmount);
  }

  get totalStr() {
    return CurrencyFormatter.format(this.total);
  }

  get totalCashAmountStr() {
    let totalCashAmount = 0;
    if (this.cashPayments.length > 0) {
      this.cashPayments.forEach((x) => {
        totalCashAmount += x.amount;
        totalCashAmount -= x.change;
      });
    }
    return CurrencyFormatter.format(totalCashAmount);
  }

  get productsData() {
    const newList = [];
    this.products.forEach((x) => {
      const item = x;
      item.alreadyInList = false;
    });
    this.products.forEach((x) => {
      let quantity = 0;
      if (!x.alreadyInList) {
        const totalItems = this.products.filter(item => x.product.id === item.product.id);
        quantity = totalItems.length;
        totalItems.forEach((item) => {
          const listItem = item;
          listItem.alreadyInList = true;
        });
        const totalCost = x.currentCost * quantity;
        const costStr = CurrencyFormatter.format(totalCost);
        const totalDiscount = x.costDiscount * quantity;
        const discountStr = CurrencyFormatter.format(totalDiscount);
        const costWithDiscount = totalCost - totalDiscount;
        const costWithDiscountStr = CurrencyFormatter.format(costWithDiscount);
        newList.push({
          id: x.product.id,
          name: x.product.name,
          quantity,
          costStr,
          calculatedDiscount: totalDiscount,
          discountStr,
          isGift: x.isGift,
          costWithDiscountStr,
          model: x.product.model,
          baseCostStr: CurrencyFormatter.format(x.baseCost),
        });
      }
    });
    newList.forEach((x) => {
      const giftProducts = this.products.filter(p => p.product.id === x.id
        && p.isGift === 1);
      const quantity = giftProducts.length;
      if (quantity > 0) {
        const product = giftProducts[0];
        const totalCost = product.currentCost * quantity;
        const costStr = CurrencyFormatter.format(totalCost);
        const totalDiscount = product.costDiscount * quantity;
        const discountStr = CurrencyFormatter.format(totalDiscount);
        const costWithDiscount = totalCost - totalDiscount;
        const costWithDiscountStr = CurrencyFormatter.format(costWithDiscount);
        newList.push({
          id: product.product.id,
          name: product.product.name,
          quantity,
          costStr,
          calculatedDiscount: totalDiscount,
          discountStr,
          isGift: product.isGift,
          costWithDiscountStr,
          model: product.product.model,
          baseCostStr: CurrencyFormatter.format(x.baseCost),
        });
      }
    });
    return newList;
  }
}
