<template>
  <div>
    <v-toolbar color="primary">
      <v-app-bar-nav-icon dark @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>
    </v-toolbar>
    <v-navigation-drawer v-model="drawer" app dark absolute temporary color="primary">
      <v-list
        dense
        nav
        class="py-0"
      >
        <v-list-item two-line :class="'px-0'">
          <v-list-item-avatar>
            <img src="./../assets/logo-ma.jpg">
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Men&uacute;</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item to="/dashboard" v-if="!canViewInvoicesAndEmployees">
          <v-list-item-icon>
            <v-icon>mdi-package-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Productos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/employees-home" v-if="!canViewInvoicesAndEmployees">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Empleados</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/clients-home">
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Clientes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/invoices">
          <v-list-item-icon>
            <v-icon>mdi-file-document-box-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Facturas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!------------------------ CATALOGOS -------------------------------------------->
        <v-list-group :value="false" v-if="!canViewInvoicesAndEmployees">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="white">
                mdi-alphabetical
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white-text">
              Catálogos
            </v-list-item-title>
          </template>

          <v-list dense
                  nav
                  class="py-0 ml-4">
            <v-list-item link to="/tags-home">
              <v-list-item-icon>
                <v-icon color="white">
                  mdi-tag
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">Categorias</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/brands-home">
              <v-list-item-icon>
                <v-icon color="white">
                  mdi-alpha-m-box
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">Marcas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/suppliers-home">
              <v-list-item-icon>
                <v-icon color="white">
                  mdi-truck
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">Proveedores</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/measuring-units-home">
              <v-list-item-icon>
                <v-icon color="white">
                  mdi-weight-kilogram
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">Unidades de medida</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-group>
        <!------------------------ CATALOGOS -------------------------------------------->

        <!------------------------ PROMOCIONES -------------------------------------------->
        <v-list-group :value="false" v-if="!canViewInvoicesAndEmployees">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="white">
                mdi-sale
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white-text">Promociones</v-list-item-title>
          </template>

          <v-list dense
                  nav
                  class="py-0 ml-4">
            <v-list-item link to="/specials-discount-home">
              <v-list-item-icon>
                <v-icon color="white">
                  mdi-currency-usd-off
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">Descuentos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/specials-combo-home">
              <v-list-item-icon>
                <v-icon color="white">
                  mdi-cash-multiple
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">Combos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-group>
        <!------------------------ PROMOCIONES -------------------------------------------->

        <!------------------------ REPORTES -------------------------------------------->
        <v-list-group :value="false" v-if="!canViewInvoicesAndEmployees">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="white">
                mdi-file-document
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white-text">Reportes</v-list-item-title>
          </template>

          <v-list dense
                  nav
                  class="py-0 ml-4">
            <v-list-item link to="/sales-report">
              <v-list-item-icon>
                <v-icon color="white">
                  mdi-cash-multiple
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">Ventas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/cash-close-report">
              <v-list-item-icon>
                <v-icon color="white">
                  mdi-cash-register
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">Corte de caja</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/product-report">
              <v-list-item-icon>
                <v-icon color="white">
                  mdi-package
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">Productos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-group>
        <!------------------------ REPORTES -------------------------------------------->

        <!------------------------ INVENTARIO -------------------------------------------->
        <v-list-group :value="false" v-if="!canViewInvoicesAndEmployees">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="white">
                mdi-file-document
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white-text">Inventario</v-list-item-title>
          </template>

          <v-list dense
                  nav
                  class="py-0 ml-4">
            <v-list-item link to="/stores-inventory">
              <v-list-item-icon>
                <v-icon color="white">
                  mdi-store
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">Merc. en sucursales</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/purchase-order">
              <v-list-item-icon>
                <v-icon color="white">
                  mdi-cash-multiple
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">Órdenes de compra</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/merch-entry">
              <v-list-item-icon>
                <v-icon color="white">
                  mdi-cash-register
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">Entrada de mercancía</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-group>
        <!------------------------ INVENTARIO -------------------------------------------->

        <v-list-item to="/login">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

  </div>
</template>

<script>
import { TokenUtils } from '@/utils/TokenUtils';
import UserService from '@/services/UserService';
import { mapGetters } from 'vuex';

const computed = {
  ...mapGetters({
    authUserData: 'authUserData',
    canViewInvoicesAndEmployees: 'canViewInvoicesAndEmployees',
  }),
};

export default {
  computed,
  name: 'app-navigation',
  data: () => ({
    drawer: null,
    miniVariant: false,
  }),
  async mounted() {
    const payload = TokenUtils.getJwtData();
    if (!payload || !payload.user_claims) {
      this.$router.push('/login');
      return;
    }

    if (payload) {
      this.$store.dispatch('setAuthUserData', payload);

      const { identity: userId } = TokenUtils.getJwtData();
      const attributes = await UserService.getUserAttributes(userId);
      this.$store.dispatch('setAttributes', attributes);
    }
  },
};
</script>

<style lang="less">
.v-list .v-list-item--active, .v-list .v-list-item--active .v-icon {
  color: white !important;
}

.white-text {
  color: white !important;
}
</style>
