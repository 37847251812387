import CurrencyFormatter from './CurrencyFormatter';

export class ProductReportSingleItem {
  constructor(productId, totalSold, name, keys, model, totalAmount) {
    this.productId = productId;
    this.totalSold = totalSold;
    this.name = name;
    this.keys = keys;
    this.model = model;
    this.totalAmount = totalAmount;
  }

  get totalAmountStr() {
    if (this.totalAmount > 0) {
      return CurrencyFormatter.format(this.totalAmount);
    }
    return CurrencyFormatter.format(0);
  }
}
