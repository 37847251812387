import Vue from 'vue';
import VueMoment from 'vue-moment';
import moment from 'moment-es6';

// set locale
require('moment/locale/es');

moment.locale('es');

Vue.use(VueMoment, { moment });
