import Axios from '@/services/Axios';
import { SalesReportSingleItem } from '@/common/models/SalesReportSingleItem';
import { CashCloseReportSingleItem } from '@/common/models/CashCloseReportSingleItem';
import { SalesReportPaymentsData } from '@/common/models/SalesReporPaymentsData';
import { ProductReportSingleItem } from '@/common/models/ProductReportSingleItem';

const resource = '/report';

export default {
  async getSalesReportData(limit, page, initDate, endDate, clientId) {
    let resp = null;

    const data = await Axios.get(`${resource}/sales/data`, {
      params: {
        limit,
        page,
        initDate,
        endDate,
        clientId,
      },
    });
    resp = [];
    data.data.forEach((item) => {
      const salesReportItem = new SalesReportSingleItem(item.id, item.foil, item.client,
        item.createdDt, item.isClosed, item.total, item.employee, item.cashPayments,
        item.debitCardPayments, item.creditCardPayments, item.checkPayments,
        item.transferPayments, item.realCashPayments, item.products);
      resp.push(salesReportItem);
    });

    return resp;
  },
  async getSalesReportCount(initDate, endDate, clientId) {
    const { data } = await Axios.get(`${resource}/sales/count`, {
      params: {
        initDate,
        endDate,
        clientId,
      },
    });
    return data.count;
  },

  async getSalesReportPaymentsData(initDate, endDate, clientId) {
    let resp = null;
    const data = await Axios.get(`${resource}/sales/payments-data`, {
      params: {
        initDate,
        endDate,
        clientId,
      },
    });
    resp = new SalesReportPaymentsData(data.data.totalAmount, data.data.cashAmount,
      data.data.debitAmount, data.data.creditAmount, data.data.checkAmount,
      data.data.transferAmount);
    return resp;
  },

  async downloadSalesReport(initDate, endDate, clientId) {
    const data = await Axios({
      method: 'get',
      url: `${resource}/sales/download`,
      responseType: 'blob',
      params: {
        initDate,
        endDate,
        clientId,
      },
    });
    const startDt = initDate.split(' ')[0];
    const endDt = endDate.split(' ')[0];
    const filename = `reporte-ventas-${startDt}-${endDt}.csv`;
    this.forceFileDownload(data, filename);
  },

  async getCashCloseReportData(limit, page, initDate, endDate) {
    let resp = null;

    const data = await Axios.get(`${resource}/cash-close/data`, {
      params: {
        limit,
        page,
        initDate,
        endDate,
      },
    });
    resp = [];
    data.data.forEach((item) => {
      const newItem = new CashCloseReportSingleItem(item.createdDt, item.amount,
        item.cashStartAmount, item.totalOrders, item.orders);
      resp.push(newItem);
    });

    return resp;
  },

  async getCashCloseReportCount(initDate, endDate) {
    const { data } = await Axios.get(`${resource}/cash-close/count`, {
      params: {
        initDate,
        endDate,
      },
    });
    return data.count;
  },

  async getProductReportData(limit, page, initDate, endDate, supplierId, brandId, productId, clientId) {
    let resp = null;

    const data = await Axios.get(`${resource}/product/data`, {
      params: {
        limit,
        page,
        initDate,
        endDate,
        supplierId,
        brandId,
        productId,
        clientId,
      },
    });
    resp = [];
    data.data.forEach((item) => {
      const newItem = new ProductReportSingleItem(item.productId, item.totalSold,
        item.name, item.keys, item.model, item.totalAmount);
      resp.push(newItem);
    });

    return resp;
  },

  async getProductReportCount(initDate, endDate, supplierId, brandId, productId, clientId) {
    const { data } = await Axios.get(`${resource}/product/count`, {
      params: {
        initDate,
        endDate,
        supplierId,
        brandId,
        productId,
        clientId,
      },
    });
    return data.count;
  },

  async getProductReportTotal(initDate, endDate, supplierId, brandId, productId, clientId) {
    const { data } = await Axios.get(`${resource}/product/total`, {
      params: {
        initDate,
        endDate,
        supplierId,
        brandId,
        productId,
        clientId,
      },
    });
    return data.total;
  },

  async downloadCashCloseReport(initDate, endDate, limit, page) {
    const data = await Axios({
      method: 'get',
      url: `${resource}/cash-close/download`,
      responseType: 'blob',
      params: {
        initDate,
        endDate,
        limit,
        page,
      },
    });
    const startDt = initDate.split(' ')[0];
    const endDt = endDate.split(' ')[0];
    const filename = `reporte-corte-${startDt}-${endDt}.csv`;
    this.forceFileDownload(data, filename);
  },

  async downloadProductReport(initDate, endDate, supplierId, brandId, productId, clientId) {
    const data = await Axios({
      method: 'get',
      url: `${resource}/product/download`,
      responseType: 'blob',
      params: {
        initDate,
        endDate,
        supplierId,
        brandId,
        productId,
        clientId,
      },
    });
    const startDt = initDate.split(' ')[0];
    const endDt = endDate.split(' ')[0];
    const filename = `reporte-productos-${startDt}-${endDt}.csv`;
    this.forceFileDownload(data, filename);
  },

  forceFileDownload(response, filename) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  },

  async deletePurchaseOrder(purchaseOrderId) {
    let response = null;
    try {
      response = await Axios.delete(`purchase-order/${purchaseOrderId}`);
    } catch (e) {
      console.error(e);
      response = e;
      return { error: 'No se pudo eliminar la venta, favor de contactar con un administrador' };
    }
    return response;
  },
};
