import CurrencyFormatter from './CurrencyFormatter';

export class PurchaseOrderProductItem {
  constructor(costDiscount, currentCost, product, isGift) {
    this.costDiscount = costDiscount;
    this.currentCost = currentCost;
    this.product = product;
    this.isGift = isGift;
  }

  get costDiscountStr() {
    return CurrencyFormatter.format(this.costDiscount);
  }

  get currentCostStr() {
    return CurrencyFormatter.format(this.currentCost);
  }
}
