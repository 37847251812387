import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0d1d4e',
        lightPrimary: '#b7f0f7',
        secondary: '#4ed289',
        accent: '#ffdd00',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        backgroundColor: '#f5f5f5',
      },
    },
  },
});
