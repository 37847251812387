import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/about',
    name: 'about',

    component: () => import('../views/About.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
  },
  {
    path: '/new-product',
    name: 'new-product',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/:id/edit-product',
    name: 'edit-product',
    component: () => import('../views/EditProduct.vue'),
  },
  {
    path: '/employees-home',
    name: 'employees-home',
    component: () => import('../views/EmployeesHome.vue'),
  },
  {
    path: '/new-employee',
    name: 'new-employee',
    component: () => import('../views/NewEmployee.vue'),
  },
  {
    path: '/:id/edit-employee',
    name: 'edit-employee',
    component: () => import('../components/CRUD/Employees/EditEmployee.vue'),
  },
  {
    path: '/clients-home',
    name: 'clients-home',
    component: () => import('../views/ClientsHome.vue'),
  },
  {
    path: '/new-client',
    name: 'new-client',
    component: () => import('../components/CRUD/Clients/CreateClient.vue'),
  },
  {
    path: '/:id/edit-client',
    name: 'edit-client',
    component: () => import('../components/CRUD/Clients/EditClient.vue'),
  },
  {
    path: '/specials-discount-home',
    name: 'specials-discount-home',
    component: () => import('../views/SpecialsHome.vue'),
  },
  {
    path: '/new-special/:type',
    name: 'new-special',
    component: () => import('../components/CRUD/Specials/CreateDiscountSpecial.vue'),
  },
  {
    path: '/:id/edit-discount-special',
    name: 'edit-discount-special',
    component: () => import('../components/CRUD/Specials/EditDiscountSpecial.vue'),
  },
  {
    path: '/specials-combo-home',
    name: 'specials-combo-home',
    component: () => import('../views/SpecialsComboHome.vue'),
  },
  {
    path: '/:id/edit-combo-special',
    name: 'combo-discount-special',
    component: () => import('../components/CRUD/Specials/EditComboSpecial.vue'),
  },
  {
    path: '/tags-home',
    name: 'tags-home',
    component: () => import('../views/TagsHome.vue'),
  },
  {
    path: '/brands-home',
    name: 'brands-home',
    component: () => import('../views/BrandsHome.vue'),
  },
  {
    path: '/suppliers-home',
    name: 'suppliers-home',
    component: () => import('../views/SuppliersHome.vue'),
  },
  {
    path: '/measuring-units-home',
    name: 'measuring-units-home',
    component: () => import('../views/MeasuringUnitsHome.vue'),
  },
  {
    path: '/sales-report',
    name: 'sales-report',
    component: () => import('../views/SalesReport.vue'),
  },
  {
    path: '/cash-close-report',
    name: 'cash-close-report',
    component: () => import('../views/CashCloseReport.vue'),
  },
  {
    path: '/product-report',
    name: 'product-report',
    component: () => import('../views/ProductReport.vue'),
  },
  {
    path: '/purchase-order',
    name: 'purchase-order',
    component: () => import('../views/PurchaseOrder.vue'),
  },
  {
    path: '/merch-entry',
    name: 'merch-entry',
    component: () => import('../views/MerchEntry.vue'),
  },
  {
    path: '/stores-inventory',
    name: 'stores-inventory',
    component: () => import('../views/StoresInventory.vue'),
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('../views/Invoices.vue'),
  },
  {
    path: '/invoice/new-sale',
    name: 'new-sale-invoice',
    component: () => import('../views/NewSaleInvoice.vue'),
  },
  {
    path: '/invoice/new-sale-global',
    name: 'new-sale-invoice-global',
    component: () => import('../views/NewSaleInvoiceGlobal.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
