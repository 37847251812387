import CurrencyFormatter from './CurrencyFormatter';

export class CashCloseReportSingleItem {
  constructor(createdDt, amount, cashStartAmount, totalOrders, orders) {
    this.cashCloseDt = createdDt;
    this.amount = amount;
    this.cashStartAmount = cashStartAmount;
    this.totalOrders = totalOrders;
    this.orders = orders;
  }

  get amountStr() {
    return CurrencyFormatter.format(this.amount);
  }

  get cashCloseDtStr() {
    let cashCloseDt = this.cashCloseDt.split('T')[0];
    cashCloseDt = cashCloseDt.split('-');
    return `${cashCloseDt[2]}/${cashCloseDt[1]}/${cashCloseDt[0]}`;
  }

  get cashStartAmountStr() {
    return CurrencyFormatter.format(this.cashStartAmount);
  }
}
