import axios from 'axios';
import { TokenUtils } from '@/utils/TokenUtils';

const baseUrl = `${process.env.VUE_APP_BASEURL}`;

export default axios.create({
  baseURL: baseUrl,
  headers: { 'Content-Type': 'application/json' },
});

export function setAuthHeaders(headers) {
  return {
    ...headers,
    Authorization: `Bearer ${TokenUtils.getToken()}`,
  };
}
