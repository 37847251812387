<template>
  <v-app style="background-color: #f5f5f5;">
    <app-navigation v-if="!['login'].includes($route.name)"></app-navigation>
    <v-content>
      <router-view></router-view>
      <router-link to="/dashboard"></router-link>
    </v-content>
    <vue-snotify></vue-snotify>
  </v-app>
</template>

<script>
import AppNavigation from '@/components/AppNavigation.vue';

export default {
  name: 'App',

  components: {
    AppNavigation,
  },

  data: () => ({
  }),
};
</script>

<style lang="less">
@import "~vue-snotify/styles/material.css";
  .title-text {
    font-size: 2rem;
    font-weight: 300;
    color: #0d1d4e;
  }
  .subtitle-text {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 0 !important;
  }
  .swal2-popup {
    font-family: 'Roboto', sans-serif !important;
  }
  .success-notif {
    background-color: #4ed289;
    color: white;
    .title {
      color: white;
    }
  }

  .snotifyToast {
    &__title {
      font-size: 1.2rem;
    }
  }
  .snotify-icon {
    max-width: 36px;
    max-height: 36px;
  }

  .snotify-async {
    background-color: #4e6dd2da;
  }

  .snotify-success {
    background-color: #4caf4fc9;
  }

  .snotify-error {
    background-color: #f44336da;
  }
</style>
