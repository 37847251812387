import Vue from 'vue';
import VueBarcodeScanner from 'vue-barcode-scanner';
import VCurrencyField from 'v-currency-field';
import Snotify, { SnotifyPosition } from 'vue-snotify';
import VueSweetalert2 from 'vue-sweetalert2';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import './plugins/moment';
import './plugins/currency';
import './plugins/currency-field';

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.use(VueBarcodeScanner);
Vue.use(VCurrencyField, {
  locale: 'en-US',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
});

const options = {
  toast: {
    position: SnotifyPosition.rightBottom,
    showProgressBar: false,
  },
};
Vue.use(Snotify, options);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
