import Vue from 'vue';
import Vuex from 'vuex';
import ReportService from '../services/ReportService';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authUserData: null,
    attributes: [],
    salesReportTableData: [],
    salesReportTableCount: 0,
    salesReportDetailsModal: false,
    salesReportDeleteModal: false,
    salesReportDetailsData: null,
    salesReportDeleteData: null,
    salesReportFilters: {},
    invoiceEmitter: null,
    cashCloseReportTableData: [],
    cashCloseReportTableCount: 0,
    cashCloseReportFilters: {},
    cashCloseReportDetailsModal: false,
    cashCloseReportDetailsData: null,
    productReportTableData: [],
    productReportTableCount: 0,
    productReportTableTotal: 0,
    productReportDetailsModal: false,
    productReportDetailsData: null,
    productReportFilters: {},
  },
  mutations: {
    SET_AUTH_USER_DATA(state, payload) {
      state.authUserData = payload;
    },
    SET_ATTRIBUTES(state, data) {
      state.attributes = data;
    },
    SHOW_REPORT_DETAILS_MODAL(state) {
      state.salesReportDetailsModal = true;
    },
    CLOSE_REPORT_DETAILS_MODAL(state) {
      state.salesReportDetailsModal = false;
    },
    SET_REPORT_DETAILS_MODAL_DATA(state, payload) {
      state.salesReportDetailsData = payload;
    },
    SHOW_REPORT_DELETE_MODAL(state) {
      state.salesReportDeleteModal = true;
    },
    CLOSE_REPORT_DELETE_MODAL(state) {
      state.salesReportDeleteModal = false;
    },
    SET_REPORT_DELETE_MODAL_DATA(state, payload) {
      state.salesReportDeleteData = payload;
    },
    SET_SALES_REPORT_LIMIT_AND_PAGE(state, payload) {
      state.salesReportFilters.limit = payload.limit;
      state.salesReportFilters.page = payload.page;
    },
    async SET_SALES_REPORT_TABLE_DATA(state) {
      const filters = state.salesReportFilters;
      if (filters.startDt && filters.endDt) {
        if (filters.startDt !== '' && filters.startDt.split(' ').length === 1) {
          filters.startDt = `${filters.startDt} 00:00:00`;
        }
        if (filters.endDt !== '' && filters.endDt.split(' ').length === 1) {
          filters.endDt = `${filters.endDt} 23:59:59`;
        }

        state.salesReportTableData = await ReportService.getSalesReportData(
          filters.limit,
          filters.page,
          filters.startDt,
          filters.endDt,
          filters.clientId,
        );
      }
    },
    async SET_SALES_REPORT_DATA_COUNT(state) {
      const filters = state.salesReportFilters;
      if (filters.startDt && filters.endDt) {
        if (filters.startDt !== '' && filters.startDt.split(' ').length === 1) {
          filters.startDt = `${filters.startDt} 00:00:00`;
        }
        if (filters.endDt !== '' && filters.endDt.split(' ').length === 1) {
          filters.endDt = `${filters.endDt} 23:59:59`;
        }

        state.salesReportTableCount = await ReportService.getSalesReportCount(
          filters.startDt,
          filters.endDt,
          filters.clientId,
        );
      }
    },
    SET_SALES_REPORT_FILTERS(state, payload) {
      const {
        limit, page, startDt, endDt, clientId,
      } = payload;

      state.salesReportFilters = {
        limit,
        page,
        startDt,
        endDt,
        clientId,
      };
    },
    SET_CASH_CLOSE_REPORT_LIMIT_AND_PAGE(state, payload) {
      state.cashCloseReportFilters.limit = payload.limit;
      state.cashCloseReportFilters.page = payload.page;
    },
    async SET_CASH_CLOSE_REPORT_TABLE_DATA(state) {
      const filters = state.cashCloseReportFilters;
      if (filters.startDt && filters.endDt) {
        if (filters.startDt !== '' && filters.startDt.split(' ').length === 1) {
          filters.startDt = `${filters.startDt} 00:00:00`;
        }
        if (filters.endDt !== '' && filters.endDt.split(' ').length === 1) {
          filters.endDt = `${filters.endDt} 23:59:59`;
        }
        const tableData = await ReportService.getCashCloseReportData(filters.limit,
          filters.page, filters.startDt, filters.endDt);
        state.cashCloseReportTableData = tableData;
      }
    },
    async SET_CASH_CLOSE_REPORT_DATA_COUNT(state) {
      const filters = state.cashCloseReportFilters;
      if (filters.startDt && filters.endDt) {
        const tableDataCount = await ReportService.getCashCloseReportCount(filters.startDt,
          filters.endDt);
        state.cashCloseReportTableCount = tableDataCount;
      }
    },
    SET_CASH_CLOSE_REPORT_FILTERS(state, payload) {
      state.cashCloseReportFilters = {
        limit: payload.limit,
        page: payload.page,
        startDt: payload.startDt,
        endDt: payload.endDt,
      };
    },
    SHOW_CASH_CLOSE_REPORT_DETAILS_MODAL(state) {
      state.cashCloseReportDetailsModal = true;
    },
    CLOSE_CASH_CLOSE_REPORT_DETAILS_MODAL(state) {
      state.cashCloseReportDetailsModal = false;
    },
    SET_CASH_CLOSE_REPORT_DETAILS_MODAL_DATA(state, payload) {
      state.cashCloseReportDetailsData = payload;
    },
    // SET_REPORT_DETAILS_MODAL_DATA(state, payload) {
    //   state.salesReportDetailsData = payload;
    // },
    SET_PRODUCT_REPORT_LIMIT_AND_PAGE(state, payload) {
      state.productReportFilters.limit = payload.limit;
      state.productReportFilters.page = payload.page;
    },
    async SET_PRODUCT_REPORT_TABLE_DATA(state) {
      const filters = state.productReportFilters;
      const { limit, page } = filters;
      const {
        supplierId, brandId, productId, clientId,
      } = filters;

      if (filters.startDt && filters.endDt) {
        if (filters.startDt !== '' && filters.startDt.split(' ').length === 1) {
          filters.startDt = `${filters.startDt} 00:00:00`;
        }
        if (filters.endDt !== '' && filters.endDt.split(' ').length === 1) {
          filters.endDt = `${filters.endDt} 23:59:59`;
        }

        state.productReportTableData = await ReportService.getProductReportData(
          limit,
          page,
          filters.startDt,
          filters.endDt,
          supplierId,
          brandId,
          productId,
          clientId,
        );
      }
    },
    async SET_PRODUCT_REPORT_DATA_COUNT(state) {
      const filters = state.productReportFilters;
      const {
        supplierId, brandId, productId, clientId,
      } = filters;

      if (filters.startDt && filters.endDt) {
        if (filters.startDt !== '' && filters.startDt.split(' ').length === 1) {
          filters.startDt = `${filters.startDt} 00:00:00`;
        }
        if (filters.endDt !== '' && filters.endDt.split(' ').length === 1) {
          filters.endDt = `${filters.endDt} 23:59:59`;
        }

        state.productReportTableCount = await ReportService.getProductReportCount(
          filters.startDt,
          filters.endDt,
          supplierId,
          brandId,
          productId,
          clientId,
        );
      }
    },
    async SET_PRODUCT_REPORT_DATA_TOTAL(state) {
      const filters = state.productReportFilters;
      const {
        supplierId, brandId, productId, clientId,
      } = filters;

      if (filters.startDt && filters.endDt) {
        if (filters.startDt !== '' && filters.startDt.split(' ').length === 1) {
          filters.startDt = `${filters.startDt} 00:00:00`;
        }
        if (filters.endDt !== '' && filters.endDt.split(' ').length === 1) {
          filters.endDt = `${filters.endDt} 23:59:59`;
        }

        state.productReportTableTotal = await ReportService.getProductReportTotal(
          filters.startDt,
          filters.endDt,
          supplierId,
          brandId,
          productId,
          clientId,
        );
      }
    },
    SET_PRODUCT_REPORT_FILTERS(state, payload) {
      const {
        limit, page, startDt, endDt,
      } = payload;
      const {
        supplierId, brandId, productId, clientId,
      } = payload;

      state.productReportFilters = {
        limit,
        page,
        startDt,
        endDt,
        supplierId,
        brandId,
        productId,
        clientId,
      };
    },
    SET_INVOICE_EMITTER(state, payload) {
      state.invoiceEmitter = payload;
    },
  },
  actions: {
    setAuthUserData({ commit }, payload) {
      commit('SET_AUTH_USER_DATA', payload);
    },
    setAttributes({ commit }, payload) {
      commit('SET_ATTRIBUTES', payload);
    },
    showReportDetailsModal({ commit }) {
      commit('SHOW_REPORT_DETAILS_MODAL');
    },
    closeReportDetailsModal({ commit }) {
      commit('CLOSE_REPORT_DETAILS_MODAL');
    },
    setReportDetailsModalData({ commit }, payload) {
      commit('SET_REPORT_DETAILS_MODAL_DATA', payload);
    },
    showReportDeleteModal({ commit }) {
      commit('SHOW_REPORT_DELETE_MODAL');
    },
    closeReportDeleteModal({ commit }) {
      commit('CLOSE_REPORT_DELETE_MODAL');
    },
    setReportDeleteModalData({ commit }, payload) {
      commit('SET_REPORT_DELETE_MODAL_DATA', payload);
    },
    setSalesReportLimitPage({ commit }, payload) {
      commit('SET_SALES_REPORT_LIMIT_AND_PAGE', payload);
      commit('SET_SALES_REPORT_TABLE_DATA');
      commit('SET_SALES_REPORT_DATA_COUNT');
    },
    setInvoiceEmitter({ commit }, payload) {
      commit('SET_INVOICE_EMITTER', payload);
    },
    getSalesReport({ commit }, payload) {
      commit('SET_SALES_REPORT_FILTERS', payload);
      commit('SET_SALES_REPORT_TABLE_DATA');
      commit('SET_SALES_REPORT_DATA_COUNT');
    },
    setCashCloseReportLimitPage({ commit }, payload) {
      commit('SET_CASH_CLOSE_REPORT_LIMIT_AND_PAGE', payload);
      commit('SET_CASH_CLOSE_REPORT_TABLE_DATA');
      commit('SET_CASH_CLOSE_REPORT_DATA_COUNT');
    },
    getCashCloseReport({ commit }, payload) {
      commit('SET_CASH_CLOSE_REPORT_FILTERS', payload);
      commit('SET_CASH_CLOSE_REPORT_TABLE_DATA');
      commit('SET_CASH_CLOSE_REPORT_DATA_COUNT');
    },
    showCashCloseReportDetailsModal({ commit }) {
      commit('SHOW_CASH_CLOSE_REPORT_DETAILS_MODAL');
    },
    closeCashCloseReportDetailsModal({ commit }) {
      commit('CLOSE_CASH_CLOSE_REPORT_DETAILS_MODAL');
    },
    setCashCloseReportDetailsModalData({ commit }, payload) {
      commit('SET_CASH_CLOSE_REPORT_DETAILS_MODAL_DATA', payload);
    },
    getProductReport({ commit }, payload) {
      commit('SET_PRODUCT_REPORT_FILTERS', payload);
      commit('SET_PRODUCT_REPORT_TABLE_DATA');
      commit('SET_PRODUCT_REPORT_DATA_COUNT');
      commit('SET_PRODUCT_REPORT_DATA_TOTAL');
    },
    setProductReportLimitPage({ commit }, payload) {
      commit('SET_PRODUCT_REPORT_LIMIT_AND_PAGE', payload);
      commit('SET_PRODUCT_REPORT_TABLE_DATA');
      commit('SET_PRODUCT_REPORT_DATA_COUNT');
      commit('SET_PRODUCT_REPORT_DATA_TOTAL');
    },
  },
  modules: {},
  getters: {
    authUserData: state => state.authUserData,
    attributes: state => state.attributes,
    salesReportDetailsModal: state => state.salesReportDetailsModal,
    salesReportDetailsData: state => state.salesReportDetailsData,
    salesReportDeleteModal: state => state.salesReportDeleteModal,
    salesReportDeleteData: state => state.salesReportDeleteData,
    salesReportTableData: state => state.salesReportTableData,
    salesReportTableCount: state => state.salesReportTableCount,
    salesReportTableFilters: state => state.salesReportFilters,
    cashCloseReportTableData: state => state.cashCloseReportTableData,
    cashCloseReportTableCount: state => state.cashCloseReportTableCount,
    cashCloseReportTableFilters: state => state.cashCloseReportFilters,
    cashCloseReportDetailsModal: state => state.cashCloseReportDetailsModal,
    cashCloseReportDetailsData: state => state.cashCloseReportDetailsData,
    productReportDetailsModal: state => state.productReportDetailsModal,
    productReportDetailsData: state => state.productReportDetailsData,
    productReportTableData: state => state.productReportTableData,
    productReportTableCount: state => state.productReportTableCount,
    productReportTableTotal: state => state.productReportTableTotal,
    productReportTableFilters: state => state.productReportFilters,
    canViewInvoicesAndEmployees: state => state.attributes.includes('VIEW_INVOICES_AND_EMPLOYEES'),
  },
});
